import React from "react";
import "./home.css";
export interface HomeProps { }

const Home: React.SFC<HomeProps> = () => {
  return (
    <div className="relative">
      <video className="w-full h-auto absolute top-0 left-0 z-10" >
        <source src="https://assets-global.website-files.com/6425f546844727ce5fb9e5ab64377ee0d96a223b1d5c6700_01_Homepage_Hero-transcode.mp4" type="video/mp4" />
      </video>
      <div className="relative z-20">
        <section id="home">
        <h2>Welcome to Resnal</h2>
        <p>This is the home page. Click on the links in the navigation to explore more.</p>
      </section>
    </div>
    </div> 
     );

};
 
export default Home;
